export const track = (action, data = {}) => {
  if (process.env.NODE_ENV === "development") {
    console.log("[DEBUG] track", action, data)
    return
  }

  if (typeof window === `undefined`) return
  if (typeof window.dataLayer !== `undefined`)
    window.dataLayer.push({ event: action })
  if (typeof window.fbq !== `undefined`) window.fbq("track", action)
}
