import React from "react"
import styled from "styled-components"
import Picker from "./Picker"

const Letter = styled.div`
  font-size: 22px;
  font-weight: bold;
  line-height: 0px;
  position: relative;
  top: 3px;
`

const SelectLayoutField = props => {
  const options = [
    {
      value: "sans",
      fontFamily: "Gotham Pro"
    },
    {
      value: "serif",
      fontFamily: "Freight"
    },
    {
      value: "script",
      fontFamily: "Great Vibes"
    }
  ]
  return (
    <Picker
      {...props}
      options={options}
      renderItem={item => {
        const IconComponent = item.component
        return <Letter style={{ fontFamily: item.fontFamily }}>T</Letter>
      }}
    />
  )
}

export default SelectLayoutField
