import React from "react"
import styled from "styled-components"
import { Form, Field } from "react-connect-form"
import * as Icon from "react-feather"
import ColorField from "../form/ColorField"
import TextField from "../form/TextField"
import Toggle from "../form/Toggle"
import LocationField from "../form/LocationField"
import ColorThemePicker from "../form/ColorThemePicker"
import SelectSizeField from "../form/SelectSizeField"
import SelectLayoutField from "../form/SelectLayoutField"
import SelectFontStyle from "../form/SelectFontStyle"
import IconPicker from "../form/IconPicker"
import SelectAngleField from "../form/SelectAngleField"
import CheckboxField from "../form/CheckboxField"

const Section = styled.div`
  padding: 20px;
  background-color: ${p => p.theme.palette.background.paper};
  color: ${p => p.theme.palette.text.primary};
  border-radius: 12px;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 4px 6px 10px, rgba(0, 0, 0, 0.1) 4px 3px 6px;
`

const SectionTitle = styled.h3`
  font-family: "Gotham Pro";
  /* font-weight: "bold"; */
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 1px;
  margin: 0px;
  display: flex;
  align-items: center;
  height: 30px;
  margin-bottom: 10px;
`

const Wrap = styled.div`
  height: 100vh;
  min-height: 100%;
`

class PosterForm extends React.Component {
  shouldComponentUpdate() {
    return true
  }

  handleChange = (data, ...rest) => {
    this.props.onChange(data)
  }

  render() {
    const props = this.props
    const { data } = props
    return (
      <Wrap>
        <Form onChange={this.handleChange} autoComplete="off" value={data}>
          <Section>
            <SectionTitle>
              {/* <Icon.MapPin size={22} style={{ marginRight: 5 }} /> */}
              Choose location
            </SectionTitle>
            <Field
              floatingLabelText="Location"
              name="location"
              type="text"
              label={data.locationLabel || ""}
              component={LocationField}
            />
          </Section>
          <Section>
            <SectionTitle>
              {/* <Icon.Layout size={22} style={{ marginRight: 5 }} /> */}
              Select layout
            </SectionTitle>

            <Field
              name="size"
              floatingLabelText="Size"
              component={SelectSizeField}
              type="text"
            />

            <Field
              name="textLayout"
              floatingLabelText="Layout"
              component={SelectLayoutField}
              type="text"
            />
            <Field
              name="framed"
              floatingLabelText="Frame"
              label="Frame it!"
              component={Toggle}
            />
            <Field
              name="pitch"
              floatingLabelText="Tilt Angle"
              component={SelectAngleField}
            />
          </Section>
          <Section>
            <SectionTitle>
              {/* <Icon.Type size={22} style={{ marginRight: 5 }} /> */}
              Set poster text
            </SectionTitle>

            <Field
              name="textTop"
              floatingLabelText="Heading"
              component={TextField}
              type="text"
            />
            <div style={{ height: 10 }}></div>
            <Field
              name="textBottom"
              floatingLabelText="Subheading"
              render={props => (
                <TextField {...props} disabled={data.syncCoords === true} />
              )}
              type="text"
            />
            <Field
              name="syncCoords"
              label="Sync with location"
              floatingLabelText="Sync"
              component={CheckboxField}
            />

            <Field
              name="textStyle"
              floatingLabelText="Font Style"
              component={SelectFontStyle}
              type="text"
            />
            <Field
              name="icon"
              floatingLabelText="Center Icon"
              component={IconPicker}
              type="text"
            />
          </Section>

          <Section>
            <SectionTitle>
              {/* <Icon.Box size={22} style={{ marginRight: 5 }} /> */}
              Choose colors
            </SectionTitle>
            <Field name="theme" component={ColorThemePicker} type="text" />
            <Field
              name="foregroundColor"
              floatingLabelText="Foreground Color"
              component={ColorField}
              type="text"
            />
            <Field
              name="backgroundColor"
              floatingLabelText="Background Color"
              component={ColorField}
              type="text"
            />
            <Field
              name="invert"
              label="Invert"
              floatingLabelText="Invert"
              component={CheckboxField}
            />
          </Section>
          <Section>
            <SectionTitle>
              {/* <Icon.Settings size={22} style={{ marginRight: 5 }} /> */}
              Other settings
            </SectionTitle>

            <Field name="showBorder" label="Circle Border" component={Toggle} />
            <Field
              name="showSecondaryRoads"
              label="Secondary Roads"
              component={Toggle}
            />
            <Field name="chonky" label="Chonky©" component={Toggle} />
            <Field
              name="banana"
              label="Banana for Scale™️"
              component={Toggle}
            />
          </Section>
          {/* <Field
            name="gradient"
            floatingLabelText={"Gradient"}
            component={GradientField}
          /> */}
          {/* <Section>
          <Field
            name="showPin"
            floatingLabelText="Show Location Pin"
            component={Checkbox}
          />
        </Section> */}
        </Form>
      </Wrap>
    )
  }
}

export default PosterForm
