import React from "react"
import TextField from "@material-ui/core/TextField"

const ConnectedTextField = props => {
  return (
    <TextField
      id={props.id || props.name}
      hintText={props.hintText}
      label={props.floatingLabelText}
      InputLabelProps={{
        shrink: true,
        style: { textTransform: "uppercase" }
      }}
      disabled={props.disabled || false}
      errorText={props.errors && props.errors.length ? props.errors[0] : ""}
      onChange={props.onChange}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      fullWidth={props.fullWidth}
      autoFocus={props.autoFocus || false}
      value={props.value || ""}
      placeholder={props.placeholder || ""}
    />
  )
}

ConnectedTextField.defaultProps = {
  fullWidth: true
}

export default ConnectedTextField
