import React from "react"
import { theme, useUITheme } from "../ui/theme"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { Checkbox } from "@material-ui/core"

const CheckboxField = props => {
  const [theme] = useUITheme()
  return (
    <FormControlLabel
      control={
        <Checkbox
          onChange={(e, v) => props.onChange(v)}
          checked={props.value || false}
          color={theme.palette.text.primary}
        />
      }
      label={props.label}
    />
  )
}

CheckboxField.defaultProps = {}

export default CheckboxField
