import React from "react"
import styled from "styled-components"
import { FieldLabel, FieldContainer } from "../ui"

const Wrap = styled.div`
  display: flex;
  //padding-top: 20px;
  flex-wrap: wrap;
  align-items: center;
`

const Box = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  opacity: ${p => (p.selected ? 1 : 0.33)};
  cursor: pointer;
`

const Picker = ({
  value,
  onChange,
  floatingLabelText,
  options,
  renderItem,
  isSelectedHandler
}) => {
  const handleChange = value => () => {
    onChange(value)
  }

  return (
    <FieldContainer>
      {floatingLabelText ? <FieldLabel>{floatingLabelText}</FieldLabel> : null}
      <Wrap style={{ marginTop: floatingLabelText ? 25 : 0 }}>
        {options.map(item => {
          const isSelected = isSelectedHandler(value, item)
          return (
            <Box
              key={item.value}
              onClick={handleChange(item.value)}
              selected={isSelected}
            >
              {renderItem(item)}
            </Box>
          )
        })}
      </Wrap>
    </FieldContainer>
  )
}

Picker.defaultProps = {
  isSelectedHandler: (value, item) => value === item.value
}

export default Picker
