import React from "react"
import styled from "styled-components"

const Wrap = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Line = styled.div`
  position: absolute;
  width: ${p => p.length}px;
  border-top: ${p => p.borderSize}px solid ${p => p.theme.palette.divider};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  left: 40px;
  top: 0px;
  span {
    display: block;
    padding: 3px 5px;
    margin: 0px auto;
    text-align: center;
    position: relative;
    top: 3px;
    // background-color: #efefef;
    color: ${p => p.color};
  }

  ::before {
    position: absolute;
    left: 0;
    top: -5px;
    width: ${p => p.borderSize}px;
    height: 10px;
    content: " ";
  }
  ::after {
    position: absolute;
    right: 0;
    top: -5px;
    width: ${p => p.borderSize}px;
    height: 10px;
    content: " ";
  }
`

const MeasureLine = ({ length, buffer, label, orientation, style }) => {
  return (
    <Wrap style={style}>
      <Line length={length} borderSize={1} buffer={buffer} color="#999">
        <span>{label}</span>
      </Line>
    </Wrap>
  )
}

export default MeasureLine
