export const colorThemes = [
  ["#4E5851", "#EBEBE3"], // midnight green / silver
  ["#FAD7BD", "#A5282C"], // rose gold / red
  ["#F3D060", "#1F2020"], // yellow / black
  ["#F9F6EF", "#0C1622"], // white / black
  ["#1F2020", "#AEE1CD"], // black / light green
  ["#E6C7C2", "#1F2020"], //black/ rose gold
  ["#54110e", "#fff2a7"],
  ["#5EB0E5", "#25282A"], // blue/space gray
  ["#0C1622", "#fff2a7"], // black / gold
  ["#D8DBE2", "#373F51"],
  ["#114B5F", "#C6DABF"],
  // ["#f1f1f1", "#104566"],
  ["#BA0C2E", "#F9F6EF"]
]
