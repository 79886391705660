import React from "react"
import styled from "styled-components"
import Drawer from "@material-ui/core/Drawer"
import * as Icon from "react-feather"

const Wrap = styled.div`
  height: 100vh;
  padding: 0px;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch; /* lets it scroll lazy */
`

const CloseContainer = styled.div`
  position: absolute;
  z-index: 100;
  top: 10px;
  right: 10px;
`

const DrawerContainer = ({ children, onClose, width, iconStyle, ...props }) => {
  const renderClose = () => {
    return typeof props.renderClose === `function` ? (
      props.renderClose({ onClose: onClose, ...props })
    ) : (
      <Icon.X onClick={onClose} style={{ cursor: "pointer" }} />
    )
  }

  return (
    <Drawer
      width={width}
      onRequestChange={onClose}
      style={{
        padding: 0,
        position: "relative",
        overflow: "hidden",
        zIndex: "auto"
      }}
      {...props}
    >
      <CloseContainer>{renderClose()}</CloseContainer>
      <Wrap>{children}</Wrap>
    </Drawer>
  )
}

DrawerContainer.defaultProps = {
  iconStyle: {}
}

export default DrawerContainer
