import numeral from "numeral"

const getCity = address => address.find(l => l.types.indexOf("locality") > -1)
const getState = address => {
  if (getCountry(address) && getCountry(address).short_name === "US")
    return address.find(
      l => l.types.indexOf("administrative_area_level_1") > -1
    )
  return null
}
const getCountry = address => {
  return address.find(l => l.types.indexOf("country") > -1)
}

const getContinent = address => {
  return address.find(l => l.types.indexOf("continent") > -1)
}

export const parseLocation = state => {
  if (state.location && state.location.lat) {
    return state.location
  } else if (state.lat) {
    return { lat: state.lat, lng: state.lng }
  } else {
    throw new Error("No location found in state", state)
  }
}

export const getZoom = ({ location }) => {
  const CITY_ZOOM_LEVEL = 11.622511482367589
  const STATE_ZOOM_LEVEL = 4.9
  const COUNTRY_ZOOM_LEVEL = 5.5
  const CONTINENT_ZOOM_LEVEL = 2.8
  // TODO: return a country, state, city, stree adress zoom
  const address = location.gmaps.address_components

  const city = getCity(address)
  const state = getState(address)
  const country = getCountry(address)
  const continent = getContinent(address)

  //console.log(JSON.stringify(location, null, 2))

  const isCity = city && country
  const isState = !isCity && state && country
  const isCountry = !isState && country
  const isContinent = !isCountry && continent

  if (isCity) return CITY_ZOOM_LEVEL
  if (isState) return STATE_ZOOM_LEVEL
  if (isCountry) return COUNTRY_ZOOM_LEVEL
  if (isContinent) return CONTINENT_ZOOM_LEVEL

  return CITY_ZOOM_LEVEL
}

const getTextTop = result => {
  const { location } = result
  const address = location.gmaps.address_components
  const city = getCity(address)
  const state = getState(address)
  const country = getCountry(address)

  const isCity = city && country
  const isState = !isCity && state && country
  const isCountry = !isState && country

  if (isCity)
    return `${city.long_name} ${state ? state.short_name : country.long_name}`
  if (isState) return `${state.long_name} ${country.short_name}`
  if (isCountry) return `${country.long_name}`

  return location.label
}

const formatCoord = (degrees, dir) =>
  `${numeral(Math.abs(degrees)).format("0.000")}°${dir}`

export const getTextBottom = location => {
  const lat = formatCoord(location.lat, location.lat < 0 ? "S" : "N")
  const lng = formatCoord(location.lng, location.lng < 0 ? "W" : "E")
  return `${lat} / ${lng}`
}

export const getLocationDataFromGoogle = result => {
  const { location } = result.location
  const data = {
    location,
    textTop: getTextTop(result),
    textBottom: getTextBottom(location),
    locationLabel: result.location.label,
    addressLocation: result.location.location,
    zoom: getZoom(result)
  }
  return data
}
