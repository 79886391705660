import React from "react"
import styled from "styled-components"
import { SketchPicker } from "react-color"
import { FieldLabel, FieldContainer } from "../ui"

const Swatch = styled.div`
  padding: 5px;
  background: "#fff";
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.5);
  display: inline-block;
  cursor: pointer;
  padding: 3px;
  border: 1px solid #999;
`

const Popover = styled.div`
  position: absolute;
  z-index: 100;
  .sketch-picker {
    background-color: red;
    span {
      color: white !important;
    }
  }
`

const SwatchColor = styled.div`
  width: 36px;
  height: 14px;
  border-radius: 2px;
  background-color: ${p => p.color};
`

class ColorField extends React.Component {
  state = { open: false }
  toggleOpen = () => {
    this.setState({ open: !this.state.open })
  }

  handleChange = c => {
    this.props.onChange(c.hex)
    //this.toggleOpen()
  }

  render() {
    const { value, floatingLabelText } = this.props
    return (
      <FieldContainer>
        <FieldLabel>{floatingLabelText}</FieldLabel>
        <div style={{ marginTop: 34 }}>
          <Swatch>
            <SwatchColor color={value} onClick={this.toggleOpen} />
          </Swatch>
          {this.state.open ? (
            <Popover>
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0
                }}
                onClick={this.toggleOpen}
              />
              <SketchPicker
                onChangeComplete={this.handleChange}
                color={value}
              />
            </Popover>
          ) : null}
        </div>
      </FieldContainer>
    )
  }
}

export default ColorField
