import React from "react"
import styled from "styled-components"
import { colorThemes } from "../ui/colors"
import Picker from "./Picker"

const size = 40

const Theme = styled.div`
  width: ${size}px;
  height: ${size}px;
  display: flex;
  overflow: hidden;
  position: relative;
  margin-bottom: 5px;
`

const Swatch = styled.div`
  display: block;
  width: ${size * 2}px;
  height: ${size * 2}px;
  background-color: ${p => p.color};
`

const SwatchWrap = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  transform-origin: ${97 + size}% ${size / (size / 7)}%;
  transform: rotate(45deg);
`

const ColorThemePicker = props => {
  const options = colorThemes.map(colors => ({
    value: colors
  }))

  const isThemeSelected = (value, item) => {
    return value && (value[0] === item.value[0] && value[1] === item.value[1])
  }

  return (
    <Picker
      {...props}
      options={options}
      isSelectedHandler={isThemeSelected}
      renderItem={item => {
        return (
          <Theme>
            <SwatchWrap>
              <Swatch color={item.value[0]} />
              <Swatch color={item.value[1]} />
            </SwatchWrap>
          </Theme>
        )
      }}
    />
  )
}

export default ColorThemePicker
