import "./LocationSearch.css"
import React from "react"
import Geosuggest from "react-geosuggest"
import styled from "styled-components"
import { FieldLabel, FieldContainer } from "../ui"
import { theme } from "../ui/theme"
import { useTheme } from "@material-ui/core"

const InputWrap = styled.div`
  input {
    background-color: ${p => p.backgroundColor};
    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: ${p => p.color};
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: ${p => p.color};
    }
    :-ms-input-placeholder {
      /* IE 10+ */
      color: ${p => p.color};
    }
    :-moz-placeholder {
      /* Firefox 18- */
      color: ${p => p.color};
    }
  }
`

const LocationField = props => {
  const {
    onChange,
    floatingLabelText,
    inputColor,
    inputStyles,
    borderColor,
    placeholder,
    backgroundColor,
    style,
    label
  } = props

  const theme = useTheme()
  const geoStyles = {
    input: {
      color: inputColor || theme.palette.text.primary,
      paddingLeft: 0,
      border: 0,
      borderBottom: `1px solid ${borderColor || theme.palette.divider}`,
      ...(inputStyles || {})
    }
  }

  return (
    <FieldContainer style={style}>
      {floatingLabelText ? (
        <FieldLabel style={{ color: inputColor }}>
          {floatingLabelText}
        </FieldLabel>
      ) : null}
      <InputWrap color={inputColor} backgroundColor={backgroundColor}>
        <Geosuggest
          style={geoStyles}
          onSuggestSelect={onChange}
          placeholder={placeholder || ""}
          initialValue={label}
        />
      </InputWrap>
    </FieldContainer>
  )
}

LocationField.defaultProps = {
  style: {}
}

export default LocationField
