import styled from "styled-components"
import { theme } from "./theme"

export const Heading = styled.h1`
  font-family: "Gotham Pro";
  color: ${p => p.theme.palette.text.primary};

  font-weight: normal;
  margin: 0px;
  margin-bottom: 5px;
  text-align: ${p => (p.center ? "center" : "left")};
`

export const Section = styled.section`
  padding: 10px 20px;
  border-top: 1px solid ${p => p.theme.palette.divider};
  border-left: 1px solid ${p => p.theme.palette.divider};
  border: 1px solid ${p => p.theme.palette.divider};
  margin-bottom: 20px;
  //border-radius: 10px;
  position: relative;

  h3 {
    top: -20px;
    position: relative;
    background-color: #000;
    display: inline-block;
    padding: 0px 5px;
  }
`

// These are mimicking MUI styles

/*
color: rgba(255, 255, 255, 0.7);
    padding: 0;
    font-size: 1rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-weight: 400;
    line-height: 1;
*/

export const FieldLabel = styled.label`
  text-transform: uppercase;
  position: absolute;
  line-height: 22px;
  top: 30px;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  z-index: 1;
  transform: scale(0.75) translate(0px, -28px);
  transform-origin: left top 0px;
  pointer-events: none;
  user-select: none;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1;
  color: ${p => p.theme.palette.text.secondary};
`

export const FieldContainer = styled.div`
  line-height: 24px;
  width: 100%;
  min-height: 72px;
  display: inline-block;
  position: relative;
  background-color: transparent;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  transition: height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  cursor: auto;
`
