import React from "react";
import styled from "styled-components";
import Picker from "./Picker";

const Angle = styled.div`
  font-size: 22px;
  font-weight: bold;
  line-height: 0px;
  position: relative;
  top: 3px;
`;

const SelectAngleField = props => {
  const options = [
    // {
    //   value: 30,
    //   label: "30°"
    // },
    {
      value: 45,
      label: "45°"
    },
    {
      value: 55,
      label: "55°"
    },
    {
      value: 60,
      label: "60°"
    }
  ];
  return (
    <Picker
      {...props}
      options={options}
      renderItem={item => {
        return <Angle>{item.label}</Angle>;
      }}
    />
  );
};

export default SelectAngleField;
