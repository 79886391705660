import React, { Component } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import PosterEditor from "../components/editor/Editor"
import { navigate } from "gatsby"

const EditorContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-overflow-scrolling: touch; /* lets it scroll lazy */
  /* background-color: #1e1f21; */
  background-color: ${p => p.theme.palette.background.default};
`

class EditorPage extends Component {
  render() {
    return (
      <Layout>
        <EditorContainer>
          <PosterEditor
            onCheckout={posterState => {
              navigate("/checkout")
            }}
          />
        </EditorContainer>
      </Layout>
    )
  }
}

export default EditorPage
