import React from "react"
import styled from "styled-components"
import { colorThemes } from "../ui/colors"
import { FieldLabel, FieldContainer } from "../ui"

const Wrap = styled.div`
  display: flex;
  padding-top: 20px;
  flex-wrap: wrap;
  align-items: center;
  min-height: 100px;
`

const Layout = styled.div`
  border: 1px solid ${p => p.theme.palette.text.primary};
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  opacity: ${p => (p.selected ? 1 : 0.33)};
  cursor: pointer;
  strong {
    font-size: 11px;
  }
`

const SelectSizeField = ({ value, onChange, floatingLabelText }) => {
  const handleChange = size => () => {
    onChange(size)
  }

  return (
    <FieldContainer>
      {floatingLabelText ? <FieldLabel>{floatingLabelText}</FieldLabel> : null}
      <Wrap>
        <Layout onClick={handleChange("18x18")} selected={value === "18x18"}>
          <strong>18x18</strong>
        </Layout>
        <Layout
          onClick={handleChange("18x24")}
          style={{ height: 40 * (689 / 518) }}
          selected={value === "18x24"}
        >
          <strong>18x24</strong>
        </Layout>
      </Wrap>
    </FieldContainer>
  )
}

export default SelectSizeField
