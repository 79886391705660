import React from "react"
import styled from "styled-components"
import { colorThemes } from "../ui/colors"
import { FieldLabel, FieldContainer } from "../ui"

const Wrap = styled.div`
  display: flex;
  padding-top: 20px;
  flex-wrap: wrap;
  align-items: center;
  min-height: 100px;
`

const Layout = styled.div`
  border: 1px solid ${p => p.theme.palette.text.primary};
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
  opacity: ${p => (p.selected ? 1 : 0.33)};
`

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${p => p.border || 1}px solid ${p => p.theme.palette.text.primary};
  width: ${p => p.size}px;
  height: ${p => p.size}px;
  border-radius: 50%;
`
const Text = styled.div`
  position: relative;
  width: 20px;
  height: 2px;
  top: -2px;
  background-color: ${p => p.theme.palette.text.primary};
`

const SelectLayoutField = ({ value, onChange, floatingLabelText }) => {
  const handleChange = textLayout => () => {
    onChange(textLayout)
  }

  return (
    <FieldContainer>
      {floatingLabelText ? <FieldLabel>{floatingLabelText}</FieldLabel> : null}
      <Wrap>
        <Layout onClick={handleChange("circle")} selected={value === "circle"}>
          <Circle border={2} size={30}>
            <Circle size={20} />
          </Circle>
        </Layout>
        <Layout onClick={handleChange("block")} selected={value === "block"}>
          <Text />
          <Circle size={20} />
        </Layout>
        <Layout onClick={handleChange("block2")} selected={value === "block2"}>
          <Circle size={20} />
          <Text style={{ top: 2 }} />
        </Layout>
      </Wrap>
    </FieldContainer>
  )
}

export default SelectLayoutField
