import React from "react"
import styled from "styled-components"
import * as Icon from "react-feather"
import icons from "../ui/icons"
import { getIconName } from "../poster/poster-utils"
import Picker from "./Picker"

const IconWrap = styled.div`
  font-size: 22px;
  font-weight: bold;
  line-height: 0px;
  position: relative;
  color: ${p => p.theme.palette.text.primary};
`

const ClearIcon = styled.a`
  display: inline-flex;
  width: auto;
  padding: 4px;
  align-items: center;
  height: 20px;
  color: ${p => p.theme.palette.text.primary};
  font-size: 12px;
  text-decoration: none;
`

const IconPicker = props => {
  const options = icons.map(icon => ({
    value: icon,
    icon
  }))

  return (
    <div>
      <Picker
        {...props}
        options={options}
        renderItem={({ icon }) => {
          const iconName = getIconName(icon)
          const IconComponent = Icon[iconName] || null
          return (
            <IconWrap>
              <IconComponent icon={icon} size={24} strokeWidth={1} />
            </IconWrap>
          )
        }}
      />
      {props.value ? (
        <ClearIcon
          href="#"
          onClick={e => {
            e.preventDefault()
            props.onChange("")
          }}
        >
          <Icon.X size={16} strokeWidth={1} />
          Clear icon
        </ClearIcon>
      ) : null}
    </div>
  )
}

export default IconPicker
